<template>
  <div class="page-wrapper">
    <h1 class="login-page-title">Créer votre compte</h1>

    <!-- Loader -->
    <div v-show="user === undefined" data-test="loader">Authenticating...</div>

    <!-- Offline instruction -->
    <div v-show="!networkOnLine" data-test="offline-instruction">
      Please check your connection, login feature is not available offline.
    </div>

    <p v-if="creationError">{{ creationError }}</p>

    <!-- Register UI -->
    <form @submit="register">
      <label for="avatar1">Avatar</label>
      <div class="avatars-select">
        <div class="avatar-option" @click.stop.prevent="selectAvatar('avatar-1')">
          <img src="" alt="" />
          <input
            id="avatar1"
            :checked="selectedAvatar === 'avatar-1'"
            type="radio"
            name="avatar"
            value="avatar-1"
            required
          />
        </div>

        <div class="avatar-option" @click.stop.prevent="selectAvatar('avatar-2')">
          <img src="" alt="" />
          <input
            id="avatar2"
            :checked="selectedAvatar === 'avatar-2'"
            type="radio"
            name="avatar"
            value="avatar-2"
            required
          />
        </div>

        <div class="avatar-option" @click.stop.prevent="selectAvatar('avatar-3')">
          <img src="" alt="" />
          <input
            id="avatar3"
            :checked="selectedAvatar === 'avatar-3'"
            type="radio"
            name="avatar"
            value="avatar-3"
            required
          />
        </div>

        <div class="avatar-option" @click.stop.prevent="selectAvatar('avatar-4')">
          <img src="" alt="" />
          <input
            id="avatar4"
            :checked="selectedAvatar === 'avatar-4'"
            type="radio"
            name="avatar"
            value="avatar-4"
            required
          />
        </div>

        <div class="avatar-option" @click.stop.prevent="selectAvatar('avatar-5')">
          <img src="" alt="" />
          <input
            id="avatar5"
            :checked="selectedAvatar === 'avatar-5'"
            type="radio"
            name="avatar"
            value="avatar-5"
            required
          />
        </div>

        <div class="avatar-option custom-file" @click="selectAvatar('custom')">
          <img src="" alt="" />
          <input
            id="custom"
            :checked="selectedAvatar === 'custom'"
            type="radio"
            name="avatar"
            value="custom"
            required
          />
          <input type="file" name="file" accept=".png, .jpg" @change="uploadCustomAvatar($event)" />
        </div>
      </div>

      <div class="avatar-preview">
        <img v-if="avatarPreview" :src="avatarPreview" />
      </div>

      <div>
        <label for="firstname">Prénom</label>
        <input id="firstname" v-model="newUser.firstname" type="text" required />
      </div>

      <div>
        <label for="lastname">Nom</label>
        <input id="lastname" v-model="newUser.lastname" type="text" required />
      </div>

      <div>
        <label for="email">Courriel</label>
        <input id="email" v-model="newUser.email" type="email" required />
      </div>

      <div>
        <label for="emailConfirm">Confirmation courriel</label>
        <input id="emailConfirm" v-model="confirmEmail" type="email" required />
      </div>

      <div>
        <label for="password">Mot de passe</label>
        <input id="password" v-model="userPassword" type="password" required />
      </div>

      <div>
        <label for="phone">Téléphone</label>
        <div class="phone-input">
          <span>+1</span>
          <the-mask
            id="phone"
            v-model="newUser.phone"
            mask="###-###-####"
            type="tel"
            :masked="true"
            required
            placeholder="xxx-xxx-xxxx"
          ></the-mask>
        </div>
      </div>

      <div>
        <label for="number">Code vendeur</label>
        <input id="number" v-model="newUser.employeeID" type="text" required />
      </div>

      <div>
        <label for="location">Succursale</label>
        <vSelect
          v-model="newUser.location"
          placeholder="Sélectionnez une succursale"
          :options="locationsListArray"
          name="location"
          required
        ></vSelect>
      </div>

      <div>
        <label for="notif-email">Notification</label>
        <label for="notif-email-value">Courriel</label>
        <input id="notif-email-value" v-model="newUser.notificationPreferences" type="radio" value="email" required />
        <label for="notif-sms-value">Texto (SMS)</label>
        <input id="notif-sms-value" v-model="newUser.notificationPreferences" type="radio" value="sms" required />
      </div>

      <button type="submit" class="bt" :class="{ 'is-loading': isLoading }">
        <div class="bt-text">
          <p>Créer mon compte</p>
        </div>
        <p class="loader">
          &nbsp;
          <svg class="main-loader" width="38" height="38" xmlns="http://www.w3.org/2000/svg" stroke="#EFF1F2">
            <g transform="translate(1 1)" stroke-width="2" fill="none" fill-rule="evenodd">
              <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="1s"
                  repeatCount="indefinite"
                />
              </path>
            </g>
          </svg>
          &nbsp;
        </p>
      </button>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { isNil } from 'lodash'
import { auth, storageRef, firebase } from '@/firebase/init'
import LocationsDB from '@/firebase/locations-db'
import { nanoid } from 'nanoid'
import { TheMask } from 'vue-the-mask'
import vSelect from 'vue-select'

export default {
  components: { TheMask, vSelect },
  data: () => ({
    isLoading: true,
    creationError: null,
    newUser: {},
    locationsList: null,
    userPassword: null,
    selectedAvatar: null,
    avatarPreview: '',
    confirmEmail: null,
    imageProgress: 0
  }),
  head() {
    return {
      title: {
        inner: 'Login'
      },
      meta: [
        {
          name: 'description',
          content: `Sign in or sign up to ${this.appTitle}`,
          id: 'desc'
        }
      ],
      link: [
        {
          rel: 'stylesheet',
          href: 'https://cdn.jsdelivr.net/npm/vue-select@3.11.2/dist/vue-select.css'
        }
      ]
    }
  },
  computed: {
    locationsListArray() {
      if (!this.locationsList) {
        return []
      }

      return this.locationsList.map(l => l.name)
    },
    ...mapState('authentication', ['user']),
    ...mapState('app', ['networkOnLine', 'appTitle'])
  },
  watch: {
    user: {
      handler(user) {
        if (!isNil(user)) {
          this.isLoading = true

          const redirectUrl = isNil(this.$route.query.redirectUrl) ? '/tableau-de-bord' : this.$route.query.redirectUrl
          this.$router.push(redirectUrl)
        } else {
          this.isLoading = false
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.resetForm()
    this.getLocationsList()
  },
  methods: {
    async uploadCustomAvatar(event) {
      const uploadedFile = event.target.files[0]

      if (!uploadedFile) {
        return
      }

      const acceptedFormats = ['image/jpeg', 'image/png']

      // Validate Format
      if (!acceptedFormats.includes(uploadedFile.type)) {
        console.log('Err: File Type')
        return
      }

      // Validate Size
      if (uploadedFile.size / 1000 > 2500) {
        console.log('Err: File Type')
        return
      }

      // Show the preview to the user
      const reader = new FileReader()
      reader.readAsDataURL(uploadedFile)
      reader.onload = () => {
        this.avatarPreview = reader.result
      }

      // Create a unique name/sku for the image
      const fileName = `${nanoid()}-${uploadedFile.name}`

      const uploadTask = storageRef.child(`users/avatars/${fileName}`).put(uploadedFile)

      this.isLoading = true

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on(
        'state_changed',
        snapshot => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          this.imageProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log('Upload is paused')
              break
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log('Upload is running')
              break
            default:
              break
          }
        },
        error => {
          // Handle unsuccessful uploads
          this.creationError = error
          this.isLoading = false
        },
        () => {
          setTimeout(async () => {
            this.newUser.avatarImageName = fileName
            this.newUser.avatarImageFormats = '300x300,600x600'

            // Create a reference to the user's cropped imaged
            const format = this.newUser.avatarImageFormats.split(',')[0]
            let imageWithFormat = this.newUser.avatarImageName.replace('.png', '').replace('.jpg', '')
            imageWithFormat = `${imageWithFormat}_${format}.png`

            // Create a reference to the file we want to download
            const imageRef = storageRef.child(`users/avatars/thumbs/${imageWithFormat}`)

            // Get the download URL
            this.newUser.avatarImageUrl = await imageRef.getDownloadURL()

            this.isLoading = false
          }, 2000)
        }
      )
    },
    async register(e) {
      e.preventDefault()

      // Validation
      if (!this.validateUserData()) {
        return
      }

      window.localStorage.setItem('userPendingCreation', JSON.stringify(this.newUser))

      try {
        await auth.createUserWithEmailAndPassword(this.newUser.email, this.userPassword)
        await auth.currentUser.sendEmailVerification()
      } catch (error) {
        this.creationError = error
        this.isLoading = false
        this.resetForm()
      }
    },
    selectAvatar(value) {
      this.newUser.avatar = value
      this.selectedAvatar = value
    },
    validateUserData() {
      if (this.newUser.email !== this.confirmEmail) {
        console.log('Err: ', 'Email confirmation')
        return false
      }

      if (!this.newUser.location) {
        console.log('Err: ', 'Location empty')
        return false
      }

      if (!this.selectedAvatar) {
        console.log('Err: ', 'Selected Avatar')
        return false
      }

      if (this.selectedAvatar === 'custom' && !this.newUser.avatarImageName) {
        console.log('Err: ', 'Custom Avatar Img')
        return false
      }

      if (!this.userPassword) {
        console.log('Err: ', 'User password')
        return false
      }

      return true
    },
    resetForm() {
      window.localStorage.setItem('userPendingCreation', false)
      // User's default values
      this.newUser = {
        avatarImageName: null,
        avatarImageFormats: null,
        notificationPreferences: 'email',
        nbSignIn: 0
      }
      this.selectedAvatar = null
      this.confirmEmail = null
      this.userPassword = null
    },
    async getLocationsList() {
      this.isLoading = true
      const locationsDB = new LocationsDB()
      this.locationsList = await locationsDB.readAll()
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.avatars-select {
  display: flex;
  justify-content: space-between;

  .avatar-option {
    position: relative;
    padding: 10px;
    border: 1px solid #e2e2e2;
    cursor: pointer;

    overflow: hidden;

    input[type='radio'] {
      position: relative;
      z-index: -1;
    }

    &.custom-file input[type='file'] {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      cursor: pointer;

      opacity: 0;
    }
  }
}
</style>
